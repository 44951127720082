import React, { useState  } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {

    const[show,setShow] = useState('none');

    const enableDropDown = () => {
        setShow('block');
    }

    const disableDropDown = () => {
        setShow('none');
    }

    return (
        <header id="header" class="fixed-top d-flex align-items-center">
            <div class="container d-flex align-items-center">
                <a href='https://ihmscampus.com/'><img class="logo me-auto" height="60" src="../../assets/img/logo/logo-IHMS-blue.png"></img></a>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12">
                            <nav id="navbar" class="navbar">
                                <ul>
                                    <li><a href='https://ihmscampus.com/'>Home</a></li>
                                    <li><a href='#register'>Register</a></li>
                                </ul>
                                <i class="bi bi-list mobile-nav-toggle" onClick={enableDropDown}></i>
                            </nav>
                            <nav style={{display: show}} className='navbar-mobile'>
                                <ul>
                                    <li><a onClick={disableDropDown} href='https://ihmscampus.com/'>Home</a></li>
                                    <li><a onClick={disableDropDown} href='#register'>Register</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;