import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from "./components";
import Home from "./pages/home";

const RoutersApp = () => {
    return (
        <BrowserRouter basename={`/`}>
            <Switch>
                <Fragment>
                    <App>
                        <Route path="/" exact component={Home} />
                    </App>
                </Fragment>
            </Switch>
        </BrowserRouter>
    )
}

export default RoutersApp;
