import React, { Component, Fragment } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import Countup from 'react-countup';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            email: '',
            no_wa: ''
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    register() {
        var formData = new FormData();

        formData.append('nama_lengkap', this.state.fullname);
        formData.append('email', this.state.email);
        formData.append('no_wa', this.state.no_wa);

        axios.post(API_URL.superStudent+'ihms/register', formData, {
            headers: { 
                'Accept': 'application/json'
            }
        })
        .then(response => response.data)
        .then(result => {
            window.snap.pay(result, {
                onSuccess: function(result){console.log('success');console.log(result);},
                onPending: function(result){console.log('pending');console.log(result);},
                onError: function(result){console.log('error');console.log(result);},            
                onClose: function(){console.log('customer closed the popup without finishing the payment');}
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    render() {
        return (
            <div>
                <section className='banner-lg' id="banner">
                    <div className='container'>
                        <div className='row text-white' style={{marginTop: '300px', marginBottom: '200px'}}>
                            <div className='col-lg-5'>
                                <h1><strong>IHMS</strong></h1>
                                <h3>International Hotel Management School</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <main id="main">
                    <section id="register">
                        <div className="container">
                            <div className="row mb-5 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    <h2 className='text-center'>Register</h2>
                                    <form>
                                        <div class="form-group mb-3">
                                            <label>Fullname</label>
                                            <input type="text" class="form-control" name="fullname" onChange={(e) => this.handleChange(e)}></input>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label>Email</label>
                                            <input type="email" class="form-control" name="email" onChange={(e) => this.handleChange(e)}></input>
                                        </div>
                                        <div class="form-group mb-3">
                                            <label>No. Whatsapp</label>
                                            <input type="number" class="form-control" name="no_wa" onChange={(e) => this.handleChange(e)}></input>
                                        </div>
                                        <div class="form-group mt-5 d-md-flex justify-content-md-end">
                                            <button className='btn-default' type="button" onClick={() => this.register()}>Register</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }

}

export default (Home);