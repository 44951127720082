import { Link } from 'react-router-dom';

const footers = () => {
    return (
        <footer id="footer">
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>IHMS Campus</span></strong>. All Rights Reserved. IHMS
                </div>
                <div class="credits">
                    Designed by IHMS IT Team
                </div>
            </div>
        </footer>
    );
}

export default footers;