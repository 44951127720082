import React from 'react';
import SmoothScroll from "smooth-scroll";
import Navbar from './commons/navbar';
import Footer from './commons/footers';

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const AppLayout = ({children}) => {
    return (
        <div>
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Navbar />
                    <div className="page-body">
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AppLayout;