//dev
// const superStudent = 'http://103.41.205.87:80/api/v1/application/';

// prod
const superStudent = 'https://superstudent.niecindonesia.com/api/v1/application/';


const API_URL = {
    superStudent
}

export default API_URL;
